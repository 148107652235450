import * as React from "react"

import { Helmet } from "react-helmet";

import PageLayout from "../components/PageLayout";
import HeroBanner from "../components/HeroBanner";
import PlainModule from "../components/PlainModule";

import BlackHoleImage from "../images/iStock-1346883613.jpg"

// markup
const NotFoundPage = () => {
  return (
    <PageLayout>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}>
        <title>Tayos Corp - Not found</title>
      </Helmet>
      <HeroBanner backgroundImage={BlackHoleImage} variant="small" />
      <PlainModule>
        <div style={{'text-align': 'center'}}>
          <h1 className="display-1 mb-5">There's no page here</h1>
          <p>
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            we couldn’t find what you were looking for...
            <br />
            {process.env.NODE_ENV === "development" ? (
              <>
                <br />
                Try creating a page in <code>src/pages/</code>.
                <br />
              </>
            ) : null}
            <br />
            Navigate with the header at the top of the page, or <a href="/">go home</a>.
          </p>
        </div>
      </PlainModule>
    </PageLayout>
  )
}

export default NotFoundPage
